import React, { useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import screenshot from '../../images/screenshot.png';

import useTheme from '@hooks/use-theme';
import { StyledFeatureHighlights } from './styles';

export default function NoDrama() {
  const { isDark } = useTheme();
  const [ selectedIndex, setSelectedIndex ] = useState(0);

  return (
    <StyledFeatureHighlights>
      <div className="contentContainer">
        <section>
          <h1>Quick Look</h1>
          <h3>Discover Some of the Key Features</h3>

          <div className="btn-group">
            <button className={
              selectedIndex === 0 ? "btn btn-active" : "btn"
            } onClick={(e) => { setSelectedIndex(0) }}>Automation</button>
            <button className={
              selectedIndex === 1 ? "btn btn-active" : "btn"
            } onClick={(e) => { setSelectedIndex(1) }}>Templates</button>
            <button className={
              selectedIndex === 2 ? "btn btn-active" : "btn"
            } onClick={(e) => { setSelectedIndex(2) }}>Services</button>
          </div>

          {selectedIndex === 0 &&
            <div>
              <StaticImage src='../../images/screenshot_processes.png' alt="screenshot" quality={100} />
            </div>
          }

          {selectedIndex === 1 &&
            <div>
              <StaticImage src='../../images/screenshot_templates.png' alt="screenshot" quality={100} />
            </div>
          }

          {selectedIndex === 2 &&
            <div>
              <StaticImage src='../../images/screenshot.png' alt="screenshot" quality={100} />
            </div>
          }
        </section>
      </div>
    </StyledFeatureHighlights>
  );
}
