import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import screenshot from '../../images/screenshot.png';

import useTheme from '@hooks/use-theme';
import { StyledNoDrama } from './styles';

export default function NoDrama() {
  const { isDark } = useTheme();
  return (
    <StyledNoDrama>
      <div className="contentContainer">
        <section>
          <h1>Panorama: No Drama</h1>
          <h3>Let us take away timely and costly manual steps, by mapping your workflow through our easy to use platform</h3>

          <StaticImage src='../../images/screenshot.png' alt="screenshot" quality={100} />
        </section>
      </div>
    </StyledNoDrama>
  );
}
