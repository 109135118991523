import tw, { styled } from 'twin.macro';

const StyledFeatureHighlights = styled.div`
  ${tw`my-2 py-10 px-5 md:(min-h-screen)`};

  section {
    ${tw`flex flex-col justify-center items-center`};

    .gatsby-image-wrapper {
      margin-top: 60px;
    }

    .btn-group {
      ${tw`mt-5`};
    }

    .btn {
      ${tw`px-1 text-sm md:(px-8 text-lg)`};
    }
  }
`;

export default StyledFeatureHighlights;
