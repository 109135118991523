import tw, { styled } from 'twin.macro';

const StyledNoDrama = styled.div`
  ${tw`my-2 py-20 px-5 md:(min-h-screen)`};
  background-color: var(--bg-alternate);
  color: var(--text-alternate);

  section {
    ${tw`flex flex-col justify-center items-center`};

    .gatsby-image-wrapper {
      margin-top: 60px;
    }
  }
`;

export default StyledNoDrama;
