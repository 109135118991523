import tw, { styled } from 'twin.macro';

import StyledHighlightCards from './highlightCards';

const StyledHighlight = styled.div`
  ${tw`relative grid [grid-template-rows:minmax(min-content, 350px) 1fr]`};

  ${StyledHighlightCards} & {
    ${tw`before:(absolute -inset-0.5 transition duration-200 bg-gradient-to-r from-purple-800 to-turquoise rounded-lg blur opacity-30) hover:before:opacity-70 border border-gray-200/25`};
    
    
    .card-content {
      display: flex;
      flex-direction: column;
      align-items: center;

      h1 {
        ${tw`mt-3 text-xl font-light tracking-[7px] mb-0`};
      }

      img {
        display: block;
        width: 35%;
        margin: 20px auto;
        padding: 20px;
        border: 10px solid var(--bg-primary);
        border-radius: 50%;
        background-color: #69b9ff;
        box-shadow: 0 0 0 1px #2e9dff;
      }

      /* .gatsby-image-wrapper {
          ${tw`m-3 p-3 text-xl font-light rounded-full bg-blue-300`};
          display: block;
          width: 35%;
          aspect-ratio: 1;
          margin: 20px auto;
          padding: 20px;
          border: 10px solid #fff;
          border-radius: 50%;
          background-color: #69b9ff;
          box-shadow: 0 0 0 1px #2e9dff;

        & picture {
          position: absolute;
          display: flex;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          justify-content: center;
          align-items: center;

          & img {
            display: inline-block;
            padding: 0;
            margin: 0;
            width: 48px;
            height: 48px;
          }
        }

      } */


      ${tw`relative p-4 bg-primary`};
      .type {
        ${tw`text-sm font-light text-indigo-500 capitalize dark:text-indigo-100`};
      }
      h3 {
        ${tw`mt-3 text-xl font-light`};
        text-transform: uppercase;
      }
      p {
        ${tw`mt-4 font-light tracking-wide`};
        text-align: center;
      }
    }
  }
`;

export default StyledHighlight;
