import tw, { styled } from 'twin.macro';

const StyledHero = styled.article`
  ${tw`min-h-screen flex flex-col justify-center items-center`};

  .hero-welcome {
    ${tw`flex flex-row flex-wrap items-center`}; // ${tw`grid [grid-template-areas:"hero"] items-center`};

    > * {
      grid-area: hero;
    }
    h1 {
      ${tw`w-3/5 pr-2 text-4xl font-light light:text-dark text-center mb-20 basis-2/5 flex-grow md:(text-6xl text-left mb-0)`};

      .greeting {
        ${tw`block mb-6 text-3xl md:text-4xl`};
      }
      span[role='img'] {
        ${tw`ml-4`};
      }
      .gatsby {
        ${tw`text-transparent bg-clip-text uppercase font-semibold bg-gradient-to-tr from-pan-light-blue to-pan-blue-700`};
      }
    }

    .picture {
      ${tw`w-full justify-self-end basis-3/5 min-w-[12rem] flex-grow flex flex-col justify-center items-center`};
      perspective: 370px;
    }

    .picture & {
      ${tw`before:(absolute -inset-0.5 transition duration-200 bg-gradient-to-r from-purple-800 to-turquoise rounded-lg blur opacity-30) hover:before:opacity-70`};
    }

    .heroImage {
      ${tw`shadow-md origin-[40%_50%]`};
    }

  }

  h2 {
    ${tw`mt-12 text-xl font-light leading-loose md:text-2xl`};
    a {
      ${tw`border-b-2 border-yellow-500 hover:text-yellow-500`};
    }
  }

  .tech {
    ${tw`grid items-center grid-cols-3 mt-12 gap-x-2 md:(mt-24 gap-x-8)`};
    li {
      ${tw`w-full mx-auto light:text-dark md:mx-0 [max-width:200px]`};
    }
  }

  .dummyHeader {
    ${tw`w-full h-[92px]`};
  }
`;

export default StyledHero;
