import tw, { styled } from 'twin.macro';

const StyledHighlightCards = styled.article`
  ${tw`my-2 pt-20 px-5 md:(pt-32 min-h-screen)`};

  section {
    h2,
    > p {
      ${tw`px-2 md:(px-0) text-center`};
      font-weight: 300;
      text-transform: uppercase;

    }

    h2 {
      ${tw`text-2xl md:text-4xl`};
      letter-spacing: 5px;
    }

    > p {
      ${tw`mx-auto mt-4 text-lg font-light tracking-wider md:(text-lg w-2/3)`};
      letter-spacing: 3px;
      opacity: 0.8;
    }

    .cards {
      ${tw`grid mt-16 gap-x-6 gap-y-10 justify-center [grid-template-columns: repeat(auto-fit, minmax(250px, 1fr))]`};
    }
  }
`;

export default StyledHighlightCards;
