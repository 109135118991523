import React from 'react';

import { Main } from '@layout';
import { Seo, Divider } from '@common';
import { Hero, HighlightCards, NoDrama, FeatureHighlights } from '@home';


export default function IndexPage() {
  return (
    <Main>
      <Seo description="Home page for a Gatsby Starter, featuring Emotion and Tailwind css" />
      <Hero />
      <Divider inTrigger={0} outTrigger="elOutY" />
      <HighlightCards />
      <Divider inTrigger="elInY" outTrigger="elOutY" />
      <NoDrama />
      <Divider inTrigger="elInY" outTrigger="elOutY" />
      <FeatureHighlights />
    </Main>
  );
}
