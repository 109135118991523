import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import imgPeople from '../../images/people.svg';
import imgZeroCode from '../../images/zerocode.svg';
import imgIntegrations from '../../images/integrations.svg';

import { StyledHighlightCards, StyledHighlight } from './styles';

export default function CardGrid() {
  return (
    <StyledHighlightCards>
      <div className="contentContainer">
        <section>
          <h2>From Start Ups to Enterprises</h2>
          <p>
            PANORAMA.IM grows with you
          </p>
          <ul className="cards">
            <StyledHighlight as="li" key={`Card1`}>
              <div className="card-content">
                <h1>Standalone</h1>
                <img src={imgPeople} width={48} height={48} alt="People" />

                <h3>Getting Started</h3>
                <p>
                  Everything you need to succeed is built in. No 3rd party tools required!
                </p>
              </div>
            </StyledHighlight>
            <StyledHighlight as="li" key={`Card2`}>
              <div className="card-content">
                <h1>Integrations</h1>
                <img src={imgIntegrations} width={48} height={48} alt="Integrations" />

                <h3>Evolve Your Process</h3>
                <p>
                  Panorama will integrate with your existing tools to provide a seamless experience.
                </p>
              </div>
            </StyledHighlight>
            <StyledHighlight as="li" key={`Card3`}>
              <div className="card-content">
                <h1>Zero Code</h1>
                <img src={imgZeroCode} width={48} height={48} alt="Zero Code" />

                <h3>No dev expertise required</h3>
                <p>
                  Model your process with our simple flow designer
                </p>
              </div>
            </StyledHighlight>
          </ul>
        </section>
      </div>
    </StyledHighlightCards>
  );
}
